import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import {CloseButton, Modal} from "react-bootstrap";
import { clsx } from "clsx";
import Select from "react-select";
import 'react-phone-number-input/style.css'
import { getCountryCallingCode } from "react-phone-number-input";
import {COUNTRIES, PAYMENT_URL} from "../../../helpers/crud-helper/consts";
import {t} from "i18next";

const purchaseSchema = Yup.object().shape({
    amount: Yup.string()
        .required("Amount is required"),
    company: Yup.object().shape({
        name: Yup.string()
            .required("Company name is required"),
        // secteur: Yup.string()
        //     .required("Company secteur is required"),
        // siret: Yup.string()
        //     .required("Company SIRET is required"),
        address: Yup.object().shape({
            // addressNumber: Yup.string()
            //     .required("Address number is required"),
            // addressStreet: Yup.string()
            //     .required("Address street is required"),
            // addressSupp: Yup.string()
            //     .required("Address supplementary is required"),
            // addressCity: Yup.string()
            //     .required("Address city is required"),
            // addressCp: Yup.string()
            //     .required("Address CP is required"),
            addressCountry: Yup.string()
                .required("Address country is required")
        }),
        phoneNumber: Yup.string()
            .required("Company phone number is required")
    }),
    mail: Yup.string()
        .email("Wrong email format")
        .required("Email is required"),
    firstname: Yup.string()
        .required("First name is required"),
    lastname: Yup.string()
        .required("Last name is required"),
    agree: Yup.boolean()
        .oneOf([true], "You should agree to our terms and conditions")
        .required("You should agree to our terms and conditions")
});

type Props = {
    packageId?: any;
    countries?: any;
    showModal: boolean;
    onHide: () => void;
    price?: any;
    paymentGateway?: any;
    appURL ?:any;
    packageName ?: string
};

const PurchaseForm: FC<Props> = ({ packageId, countries, showModal, onHide, price, paymentGateway, appURL , packageName}) => {
    const { t } = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [countryCode, setCountryCode] = useState<any>('');
    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState<any>('')

    const initialValues =
        {currency: "eur",
        amount: "",
        description: "",
        company: {
            name: "",
            secteur: "",
            siret: "",
            address: {
                addressNumber: "",
                addressStreet: "",
                addressSupp: "",
                addressCity: "",
                addressCp: "",
                addressCountry: ""
            },
            phoneNumber: ""
        },
        mail: "",
        firstname: "",
        lastname: "",
            agree: false,

    };

    const formik = useFormik({
        initialValues,
        validationSchema: purchaseSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                setLoading(true);
                const url = `${PAYMENT_URL}/${appURL}`
                const requestBody = {
                    currency: 'eur',
                    amount: values.amount,
                    description: 'Spacefit',
                    company: {
                        name: values.company.name,
                        secteur: values.company.secteur,
                        siret: values.company.siret,
                        address: {
                            addressNumber: values.company?.address?.addressNumber,
                            addressStreet: values.company?.address?.addressStreet,
                            addressSupp: values.company?.address?.addressSupp,
                            addressCity: values.company?.address?.addressCity,
                            addressCp:values.company?.address?.addressCp,
                            addressCountry: values.company?.address?.addressCountry
                        },
                        phoneNumber: values.company.phoneNumber
                    },
                    mail: values.mail,
                    firstname: values.firstname,
                    lastname: values.lastname
                };


                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    // setErrorMessage(response)
                    const errorData = await response.json();

                    // Check if the errorData contains an error message
                    if (errorData && errorData.error) {
                        setErrorMessage(errorData.error) ;
                    }
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                window.location.href = data.payment_url


            } catch (error) {
                console.error('Error:', error);
                // Handle error
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });

    const handleCountryChange = (selectedOption: any) => {
        setCountryCode(getCountryCallingCode(selectedOption.value))
        setSelectedCountry(selectedOption);
    };

    useEffect(() => {
        formik.setFieldValue("amount", price);
    }, [price]);

    return (
        <Modal show={showModal} onHide={onHide} centered size={'lg'}>
            <Modal.Header closeButton className={'border-bottom-0 pb-0'}>
                <Modal.Title ><div dangerouslySetInnerHTML={{__html: t('purchase.form.title', { package: packageName }) + ':'}}  />


                </Modal.Title>


            </Modal.Header>
            <p className={'fs-16 ps-3'}>
                {t('purchase.form.text')}
            </p>
            <div className={'border-top'}/>
            <Modal.Body className={' '}>

                <form className="form w-100" onSubmit={formik.handleSubmit} noValidate>

                    {formik.submitCount > 0 && !formik.isValid && (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {t('purchase.form.requiredMessage')}
                        </div>
                    )}
                    {errorMessage &&
                        <div className="alert alert-danger alert-dismissible fade show  ">
                            {errorMessage}
                        </div>

                    }
                    <div className="form-group row">
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("firstname")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.firstname && formik.errors.firstname},
                                {"is-valid": formik.touched.firstname && !formik.errors.firstname}
                            )} placeholder={t('contact.firstName')}/>
                        </div>
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("lastname")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.lastname && formik.errors.lastname},
                                {"is-valid": formik.touched.lastname && !formik.errors.lastname}
                            )} placeholder={`${t('contact.lastName')}`}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("mail")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.mail && formik.errors.mail},
                                {"is-valid": formik.touched.mail && !formik.errors.mail}
                            )} placeholder={`${t('purchase.form.email')}`}/>
                        </div>
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.name")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.company?.name && formik.errors.company?.name},
                                {"is-valid": formik.touched.company?.name && !formik.errors.company?.name}
                            )} placeholder={`${t('contact.company')}`}/>
                        </div>
                    </div>

                    <div className="form-group row">

                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.secteur")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.company?.secteur && formik.errors.company?.secteur},
                                {"is-valid": formik.touched.company?.secteur && !formik.errors.company?.secteur}
                            )} placeholder={`${t('purchase.form.sector')}`}/>
                        </div>
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.siret")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.company?.siret && formik.errors.company?.siret},
                                {"is-valid": formik.touched.company?.siret && !formik.errors.company?.siret}
                            )} placeholder={`${t('purchase.form.siret')}`}/>
                        </div>
                    </div>
                    <div className="form-group row">

                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <Select

                                className={clsx(
                                    "w-100",
                                    {"invalid-select": formik.touched.company?.address?.addressCountry && formik.errors.company?.address?.addressCountry},
                                    {"valid-select": formik.touched.company?.address?.addressCountry && !formik.errors.company?.address?.addressCountry}
                                )}
                                closeMenuOnSelect={true}
                                placeholder={t('contact.country')}
                                options={COUNTRIES.map((e: {
                                    label: any;
                                    value: any
                                }) => ({label: e.label, value: e.value}))}
                                isSearchable={true}
                                onChange={(newValue: any) => {
                                    formik.setFieldValue('company.address.addressCountry', newValue.label);
                                    handleCountryChange(newValue);
                                }
                                }
                            />

                        </div>
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <div className="input-group">
                                {countryCode &&
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text form-control main-input">+{countryCode}</span> {/* Replace XX with your country code */}
                                    </div>
                                }

                                <input
                                    type="text"
                                    {...formik.getFieldProps("company.phoneNumber")}
                                    className={clsx(
                                        "form-control main-input",
                                        {"is-invalid": formik.touched.company?.phoneNumber && formik.errors.company?.phoneNumber},
                                        {"is-valid": formik.touched.company?.phoneNumber && !formik.errors.company?.phoneNumber}
                                    )}
                                    placeholder={t('contact.phoneNumber')}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="form-group row">

                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.address.addressStreet")}
                                   className={clsx(
                                       "form-control main-input",
                                       {"is-invalid": formik.touched.company?.address?.addressStreet && formik.errors.company?.address?.addressStreet},
                                       {"is-valid": formik.touched.company?.address?.addressStreet && !formik.errors.company?.address?.addressStreet}
                                   )} placeholder={`${t('purchase.form.address1')}`}/>
                        </div>
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.address.addressSupp")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.company?.address?.addressSupp && formik.errors.company?.address?.addressSupp},
                                {"is-valid": formik.touched.company?.address?.addressSupp && !formik.errors.company?.address?.addressSupp}
                            )} placeholder={`${t('purchase.form.address2')}`}/>
                        </div>
                    </div>
                    <div className="form-group row">

                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.address.addressCity")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.company?.address?.addressCity && formik.errors.company?.address?.addressCity},
                                {"is-valid": formik.touched.company?.address?.addressCity && !formik.errors.company?.address?.addressCity}
                            )} placeholder={`${t('purchase.form.city')}`}/>
                        </div>
                        <div className="col-lg-6 col-6 mt-2 mb-2">
                            <input type="text" {...formik.getFieldProps("company.address.addressCp")} className={clsx(
                                "form-control main-input",
                                {"is-invalid": formik.touched.company?.address?.addressCp && formik.errors.company?.address?.addressCp},
                                {"is-valid": formik.touched.company?.address?.addressCp && !formik.errors.company?.address?.addressCp}
                            )} placeholder={`${t('purchase.form.postalCode')}`}/>
                        </div>
                    </div>

                    <div className="form-group row">

                        <div className={'col-md-12 mt-2 mb-2'}>
                            <input
                                type="checkbox"
                                className="custom-checkbox"
                                {...formik.getFieldProps("agree")}
                            />
                            <span className={clsx(
                                "ms-2",
                                {"text-danger": formik.touched.agree && formik.errors.agree}
                            )}
                                  dangerouslySetInnerHTML={{__html: t('purchase.form.agree')}}/>
                        </div>


                    </div>

                    <div className="row justify-content-center mt-lg-3 mt-3 mb-5">
                        <div className="col-lg-3 col-10">
                            <button className="btn btn-nav-primary p-btn w-100"
                                    disabled={formik.isSubmitting} type="submit">
                                {!loading && (
                                    <span className="indicator-label">
                    {t('purchase.form.submit')}
                  </span>
                                )}
                                {loading && (<span className="indicator-progress" style={{display: "block"}}><span
                                    className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default PurchaseForm;

